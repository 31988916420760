import { FC, Fragment } from "react";
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, Heading, VStack } from "@chakra-ui/react";
import { PrismicLink, ShiIcon } from "src/atoms";

const MobileStack: FC<{
  linksGroups: Gatsby.SiteFooterDataBodyLinksGroupFragment[];
}> = ({ linksGroups }) => {
  return (
    <Accordion
      w="full"
      display={{ base: "block", md: "none" }}
      allowToggle
    >
      {linksGroups.map((group) => {
        if (!group) return null;
        return (
          <AccordionItem
            as={VStack}
            key={group.primary?.title}
            className="item"
            flex="1"
            textAlign="left"
            align="flex-start"
            spacing="space-12"
            borderTop="none"
            borderBottom="1px solid white"
            marginBottom="space-24"
          >
            {({ isExpanded }) => (
              <Fragment>
                <AccordionButton
                  paddingTop="0"
                  paddingBottom="space-32"
                  paddingX="0"
                  sx={{
                    "& + div": {
                      marginTop: "0 !important",
                      width: "100%",
                    },
                  }}
                >
                  <Heading
                    as="h3"
                    variant="heading-3"
                    flex="1"
                    textAlign="left"
                    fontFamily="Mabry Pro"
                    fontSize="font-20"
                    lineHeight="base"
                    color="white"
                  >
                    {group.primary?.title}
                  </Heading>

                  <ShiIcon
                    name="chevron"
                    width="20px"
                    transform={`scale(1.25) ${isExpanded ? "rotate(180deg)" : "rotate(0deg)"}`}
                    sx={{
                      path: {
                        fill: "white",
                      },
                    }}
                  />
                </AccordionButton>

                <AccordionPanel
                  display="flex"
                  flexDirection="column"
                  gap="space-24"
                  paddingTop="0"
                  paddingBottom="space-32"
                  paddingX="0"
                >
                  {group.items?.map((item) => {
                    if (!item?.link) return null;

                    return (
                      <PrismicLink
                        display="block"
                        link={item.link}
                        key={item.link_label}
                        fontSize="font-16"
                        lineHeight="22px"
                        color="white"
                      >
                        {item.link_label}
                      </PrismicLink>
                    );
                  })}
                </AccordionPanel>
              </Fragment>
            )}
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default MobileStack;
