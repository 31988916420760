import { BoxProps, HStack } from "@chakra-ui/react";

import { Box } from "@chakra-ui/react";
import { FC } from "react";
import { SvgOrImg } from "src/components/SvgOrImg";
import Renderer from "src/lib/renderer";
import { Element } from "@prismicio/react";
import { hyperlink } from "src/lib/renderer/elements/hyperlink";
import paragraph from "src/lib/renderer/elements/paragraph";

const HighlightBlock: FC<{
  highlights: Gatsby.SiteFooterDataBodyHighlightsFragment | undefined;
} & BoxProps> = ({
  highlights,
  ...rest
}) => {
  return (
    <HStack
      spacing={{ base: 0, md: "space-40" }}
      alignItems="flex-start"
      justifyContent="space-between"
      mt={{ base: "space-40", md: "space-70" }}
      mb="space-70"
      py={{ base: 0, md: "space-70" }}
      borderTop={{ base: "none", md: "1px solid white" }}
      borderBottom={{ base: "none", md: "1px solid white" }}
      {...rest}
    >
      {highlights?.items?.map((highlight, index) => {
        if (!highlight) return null;

        return (
          <HStack
            key={index}
            w="full"
            alignItems={{ base: "center", md: "flex-start" }}
            justifyContent={{ base: "center", md: "flex-start" }}
            spacing="space-24"
            minH={{ base: "92px", md: "auto" }}
          >
            <Box>
              {highlight.illustration?.document?.__typename ===
                "PrismicElementImage" 
                  ? <SvgOrImg
                      imageFile={highlight.illustration?.document.data?.image_file}
                      size={64}
                      colorChanges={[["#25241D", "white"]]}
                    />
                  : null
              }
            </Box>
            <Box display={{ base: "none", md: "block" }}>
              <Renderer
                overrides={{
                  [Element.paragraph]: (args: any) =>
                    paragraph({
                      ...args,
                      overrideProps: {
                        fontSize: "font-16",
                        lineHeight: "short",
                        color: "white",
                        _last: {
                          mb: 0,
                        },
                      },
                    }),
                  [Element.hyperlink]: (args: any) =>
                    hyperlink({
                      ...args,
                      overrideProps: {
                        marginTop: "space-16",
                        py: "space-8",
                        fontSize: "font-15",
                        lineHeight: "22px",
                        color: "white",
                        borderBottomWidth: "1px",
                      },
                    }),
                }}
                field={highlight.content}
              />
            </Box>
          </HStack>
        );
      })}
    </HStack>
  );
};

export default HighlightBlock;
