import { FC } from "react";
import {
  HStack,
  IconButton,
  StackProps,
} from "@chakra-ui/react";
import { PrismicLink, ShiIcon } from "src/atoms";

import { pick } from "ramda";
const SocialBlock: FC<Gatsby.SiteFooterFragment & StackProps> = ({
  data,
  ...rest
}) => {
  return (
    <HStack spacing="space-24" {...rest}>
      {Object.entries(
        pick(["twitter", "linkedin", "facebook", "instagram"], data),
      ).map(
        ([network, link]) =>
          link && (
            <IconButton
              as={PrismicLink}
              link={link}
              target="_blank"
              rel="noopener"
              key={network}
              aria-label={network}
              boxSize="40px"
              bg="transparent"
              borderRadius="50%"
              color="white"
              border="1px solid"
              borderColor="white"
              _hover={{
                bg: "transparent",
              }}
              icon={(
                <ShiIcon
                  fontSize="36px"
                  name={network as any}
                  sx={{
                    path: {
                      fill: "white",
                    },
                  }}
                />
              )}
            />
          ),
      )}
    </HStack>
  );
};

export default SocialBlock;
