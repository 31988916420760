import { FC } from "react";
import { Heading, Stack, VStack } from "@chakra-ui/react";

import { PrismicLink } from "src/atoms";

const DesktopStack: FC<{
  linksGroups: Gatsby.SiteFooterDataBodyLinksGroupFragment[];
}> = ({ linksGroups }) => {
  return (
    <Stack
      direction="row"
      align="flex-start"
      spacing="0"
      w="full"
      display={{ base: "none", md: "flex" }}
    >
      <Stack
        direction="row"
        spacing={{ base: "space-40", xl: "space-80" }}
        w="full"
      >
        {linksGroups.map((group) => {
          if (!group) return null;
          return (
            <VStack
              key={group.primary?.title}
              flex={1}
              textAlign="left"
              align="flex-start"
              spacing="space-24"
            >
              <Heading
                as="h3"
                marginBottom="space-8"
                variant="heading-3"
                fontFamily="Mabry Pro"
                fontSize="font-20"
                lineHeight="base"
                color="white"
              >
                {group.primary?.title}
              </Heading>

              {group.items?.map((item) => {
                if (!item?.link) return null;

                return (
                  <PrismicLink
                    link={item.link}
                    key={item.link_label}
                    fontSize="font-16"
                    lineHeight="short"
                    color="white"
                  >
                    {item.link_label}
                  </PrismicLink>
                );
              })}
            </VStack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default DesktopStack;
