import { FC } from "react";
import {
  Box,
  Container,
  Stack,
  Wrap,
  IconButton,
  Flex,
  VStack,
} from "@chakra-ui/react";
import { graphql, useStaticQuery, Link as GatsbyLink } from "gatsby";
import { PrismicLink, ShiIcon } from "src/atoms";
import { Link } from "src/atoms/PrismicLink";
import useOpenCookiesSettings from "src/components/Cookies/useOpenCookiesSettings";
import Renderer from "src/lib/renderer";
import { Element } from "@prismicio/react";
import paragraph from "src/lib/renderer/elements/paragraph";
import DesktopStack from "./components/DesktopStack";
import MobileStack from "./components/MobileStack";
import RatingStack from "./components/RatingStack";
import SocialBlock from "./components/SocialBlock";
import StoreBlock from "./components/StoreBlock";
import HighlightBlock from "./components/HighlightBlock";

const SiteFooter: FC<{
  isFull: boolean;
  override?: Gatsby.Maybe<Gatsby.SiteFooterFragment>;
}> = ({ isFull, override }) => {
  const { prismicSiteFooter } = useStaticQuery<Gatsby.SiteFooterDataQuery>(graphql`
    query SiteFooterData {
      prismicSiteFooter(tags: { eq: "Is default instance" }) {
        ...SiteFooter
      }
    }
  `);

  const data = override?.data ?? prismicSiteFooter?.data;

  if (!data?.body) throw Error();

  const { legal_links, legal_mentions } = data;

  let highlights: Gatsby.SiteFooterDataBodyHighlightsFragment | undefined;

  const linksGroups: Gatsby.SiteFooterDataBodyLinksGroupFragment[] = [];

  data.body.forEach((slice) => {
    if (slice?.__typename === "PrismicSiteFooterDataBodyHighlights") {
      highlights = slice;
    }
    if (slice?.__typename === "PrismicSiteFooterDataBodyLinksGroup") {
      linksGroups.push(slice);
    }
  });

  const openCookieSettings = useOpenCookiesSettings();

  return (
    <Container
      as="footer"
      marginTop={{ base: "space-40", md: "52px" }}
      paddingX="0"
      paddingY={{ base: "space-70", md: "space-120" }}
      bg="grey-0"
      sx={{
        "@media print": {
          display: "none",
        },
      }}
    >
      <Container
        size="block"
        as="nav"
        paddingY="0"
        marginX="auto"
        paddingX={{ base: "space-24", md: "space-58", "2xl": 0 }}
      >
        {isFull && (
          <Box maxW="unset" mb={{ base: "space-40", md: "space-80" }}>
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "space-70", lg: "space-160" }}
            >
              <VStack
                spacing="0"
                alignItems={{ base: "center", md: "flex-start" }}
              >
                <IconButton
                  as={GatsbyLink}
                  aria-label="Shine logo"
                  variant="ghost"
                  paddingY="0"
                  icon={
                    <ShiIcon
                      name="ShineLogo"
                      width={{ base: "93px", md: "96px" }}
                      height="auto"
                      sx={{
                        path: {
                          fill: "white",
                        },
                      }}
                    />
                  }
                  to="/"
                  _hover={{
                    backgroundColor: "initial",
                  }}
                />

                <RatingStack
                  globalRating={data.global_rating?.text}
                  appStoreRating={data.app_store_rating?.text}
                  playStoreRating={data.play_store_rating?.text}
                  trustpilotRating={data.trustpilot_rating?.text}
                />
              </VStack>

              <Box display={{ base: "block", md: "none" }}>
                <StoreBlock />
              </Box>

              <DesktopStack linksGroups={linksGroups} />
            </Stack>

            <Flex
              display={{ base: "none", md: "flex" }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gridGap="space-16"
              my="space-70"
              maxW="initial"
            >
              <StoreBlock />

              <SocialBlock data={data} />
            </Flex>

            <HighlightBlock highlights={highlights} />

            <MobileStack linksGroups={linksGroups} />

            <Flex
              display={{ base: "flex", md: "none" }}
              justifyContent="center"
              my="space-70"
            >
              <SocialBlock data={data} />
            </Flex>
          </Box>
        )}

        <Stack
          maxW="unset"
          spacing="space-32"
        >
          <Box
            sx={{
              a: {
                color: "inherit",
              },
              "p:last-of-type": {
                marginBottom: "0",
              },
            }}
          >
            <Renderer
              overrides={{
                [Element.paragraph]: (args: any) =>
                  paragraph({
                    ...args,
                    overrideProps: {
                      marginBottom: "space-32",
                      fontSize: "font-16",
                      lineHeight: "22px",
                      color: "grey-400",
                    },
                  }),
              }}
              field={legal_mentions}
            />
          </Box>

          <Wrap
            direction="row"
            color="grey-400"
            spacing={{ base: "space-8", md: "space-24" }}
            textAlign={{ base: "left", md: "center" }}
            fontSize="font-16"
            lineHeight="22px"
            justify={{ base: "flex-start", md: "center" }}
          >
            {legal_links?.map((legalLink) =>
              legalLink?.is_cookies_link ? (
                <Link key={legalLink?.link_label} onClick={openCookieSettings}>
                  {legalLink.link_label}
                </Link>
              ) : (
                legalLink?.link && (
                  <PrismicLink
                    key={legalLink?.link_label}
                    link={legalLink.link}
                  >
                    {legalLink.link_label}
                  </PrismicLink>
                )
              ),
            )}
          </Wrap>
        </Stack>
      </Container>
    </Container>
  );
};

export default SiteFooter;
